import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { debounceTime, filter, fromEvent, map, merge, Observable, scan, Subject, switchMap, takeUntil } from 'rxjs';
import { parseCurveCoords } from 'src/app/core/leaflet-helper/curve-coords';
import { Unsubscriber } from 'src/app/core/unsubscriber/unsubscriber';
import { MapElement } from 'src/app/maps/domain/masterdata/map-element.model';
import { SisMapAssetCategory } from 'src/app/maps/domain/masterdata/sismap-asset-category.enum';
import { MapStateService } from 'src/app/maps/map/map-state.service';
import { AssetEditorService } from 'src/app/maps/map/sidepane/asset-editor-tab/asset-editor.service';
import { AssetEditorConfig } from 'src/app/maps/map/sidepane/asset-editor-tab/asset-editor-config.model';

@Component({
  selector: 'sis-sismap-sidepane-asset-editor-tab',
  templateUrl: './asset-editor-tab.component.html',
  styleUrls: ['./asset-editor-tab.component.scss'],
})
export class AssetEditorTabComponent extends Unsubscriber implements OnInit {
  @Output() changeToAddAssetTab = new EventEmitter<void>();

  asset: MapElement;
  iconPositionInputs: FormArray;
  formGroup: FormGroup;
  config: AssetEditorConfig = {
    editableProperties: [],
    adaptForApi: null,
  };
  maxIconCountReached: boolean;
  canRemoveIcon: boolean;
  canSave: boolean;
  canBeDuplicated: boolean;
  originalAssetMaxIconPositionIndex: number;
  hasChanges: boolean;
  selectedIconIndex: number;

  category: string;

  private readonly kill$ = new Subject<void>();
  private readonly errors = new Set<string>();

  readonly hasErrors$ = this.mapStateService.selectedElement$.pipe(
    switchMap(({ element }) =>
      this.assetEditorService.assetsWithError$.pipe(map((assetsWithError) => assetsWithError.has(element?.guid)))
    )
  );

  constructor(
    private mapStateService: MapStateService,
    private assetEditorService: AssetEditorService,
    private changeDetector: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.mapStateService.selectedElement$.pipe(takeUntil(this.onDestroy$)).subscribe(({ element, iconIndex }) => {
      if (!element) {
        this.asset = null;
        this.category = null;
        this.changeToAddAssetTab.emit();
        this.detectChanges();
        return;
      }

      this.config = this.assetEditorService.configs.get(element.category) ?? {
        editableProperties: [],
        adaptForApi: null,
      };
      this.errors.clear();

      this.setOriginalAsset(element);

      this.asset = element;
      this.category = SisMapAssetCategory[element.category];
      const originalAsset = this.assetEditorService.editedAssetOriginals.get(element.guid);
      this.originalAssetMaxIconPositionIndex = originalAsset.iconPositions?.length - 1;
      this.selectedIconIndex = iconIndex;

      this.canBeDuplicated = this.config.canBeDuplicated;

      this.initForm();
    });

    this.mapStateService.elementMoved$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(({ element, iconIndex, newPosition }) => {
        this.setOriginalAsset(element);

        element.iconPositions[iconIndex] = newPosition;

        this.mapStateService.selectElement(element, iconIndex);
      });

    this.setupArrowkeyListeners();
  }

  addPosition(): void {
    if (this.maxIconCountReached) {
      return;
    }

    const x = this.asset.iconPositions[0]?.x ?? 200;
    const y = (this.asset.iconPositions[0]?.y ?? 200) + this.asset.iconWidth;

    this.iconPositionInputs.push(
      new FormGroup({
        x: new FormControl<number>(x, [Validators.required]),
        y: new FormControl<number>(y, [Validators.required]),
      })
    );

    this.detectChanges();
  }

  removePosition(removedIndex: number): void {
    // an asset must have a path or at least one icon
    if (!this.canRemoveIcon) {
      return;
    }

    this.iconPositionInputs.removeAt(removedIndex);
  }

  delete(): void {
    const assetToRemove = this.asset;
    this.mapStateService.selectElement(null, null);
    this.assetEditorService.removeAsset(assetToRemove);
  }

  duplicate(): void {
    const curPosition = this.asset.iconPositions[0];
    const assetClone = Object.assign(Object.create(Object.getPrototypeOf(this.asset)), this.asset);
    assetClone.guid = undefined;
    this.assetEditorService.addAsset(assetClone, { x: curPosition.x - 20, y: curPosition.y + 20 });
  }

  reset(): void {
    const original = this.getOriginalAsset(this.asset);
    Object.keys(this.asset).forEach((key) => {
      this.asset[key] = original[key];
    });

    this.initForm();
    this.emitChangedEvent();
  }

  resetProperty(key: string): void {
    const original = this.getOriginalAsset(this.asset);
    this.asset[key] = original[key];

    this.formGroup.controls[key].setValue(this.asset[key]);
  }

  resetPosition(index: number): void {
    if (index > this.originalAssetMaxIconPositionIndex) {
      return;
    }

    const original = this.getOriginalAsset(this.asset);

    this.asset.iconPositions[index].x = original.iconPositions[index].x;
    this.asset.iconPositions[index].y = original.iconPositions[index].y;

    this.iconPositionInputs.at(index).patchValue(this.asset.iconPositions[index]);
  }

  private initForm(): void {
    this.kill$.next();

    this.iconPositionInputs = new FormArray([]);
    this.formGroup = new FormGroup(
      {
        iconPositions: this.iconPositionInputs,
      },
      {
        validators: [this.validatePath()],
      }
    );

    this.addPositionInputs();

    this.config.editableProperties.forEach((property) => {
      this.formGroup.addControl(
        property.key,
        new FormControl(this.asset[property.key], this.validateProperty.bind(this))
      );
    });

    this.formGroup.valueChanges
      .pipe(takeUntil(this.onDestroy$), takeUntil(this.kill$), debounceTime(10))
      .subscribe((value) => {
        const properties = this.config.editableProperties;

        properties
          .filter((p) => p.key !== AssetEditorService.path)
          .forEach((property) => {
            const newValue = value[property.key];

            if ((newValue === '' || newValue == null) && property.isNullable) {
              this.asset[property.key] = null;
            } else {
              if (property.isNumber) {
                let parsed = Number.parseFloat(newValue);
                if (isNaN(parsed)) {
                  parsed = null;
                }
                this.asset[property.key] = parsed;
              } else {
                this.asset[property.key] = newValue;
              }
            }
          });

        this.asset.iconPositions = (value['iconPositions'] as Array<{ x: string; y: string }>).map(
          ({ x, y }, index: number) => {
            if (isNaN(Number.parseInt(x, 10)) || isNaN(Number.parseInt(y, 10))) {
              return this.asset.iconPositions[index];
            }
            return {
              x: Number.parseInt(x, 10),
              y: Number.parseInt(y, 10),
            };
          }
        );

        if (properties.some((property) => property.key === AssetEditorService.path)) {
          if (this.formGroup.get(AssetEditorService.path).valid) {
            this.asset.path = value[AssetEditorService.path] ?? null;
          }
        }

        this.emitChangedEvent();
      });

    this.checkChanges();
    this.detectChanges();
  }

  private addPositionInputs(): void {
    this.asset?.iconPositions?.forEach((iconPosition) =>
      this.iconPositionInputs.push(
        new FormGroup({
          x: new FormControl<number>(iconPosition.x, [
            Validators.required,
            (c): ValidationErrors | null => (!isNaN(Number.parseInt(c.value, 10)) ? null : { notANumber: true }),
          ]),
          y: new FormControl<number>(iconPosition.y, [
            Validators.required,
            (c): ValidationErrors | null => (!isNaN(Number.parseInt(c.value, 10)) ? null : { notANumber: true }),
          ]),
        })
      )
    );
  }

  private emitChangedEvent(): void {
    this.mapStateService.updateMapElement(this.asset);

    this.checkChanges();
  }

  private checkChanges(): void {
    this.maxIconCountReached = this.asset?.iconPositions?.length >= this.config.maxIconCount;
    this.canRemoveIcon = !(this.asset?.iconPositions?.length <= 1 && !this.asset.path);

    let hasChanges = false;

    if (this.asset) {
      const original = this.getOriginalAsset(this.asset);

      hasChanges =
        this.config.editableProperties.some((property) => this.asset[property.key] !== original[property.key]) ||
        JSON.stringify(this.asset.iconPositions) !== JSON.stringify(original.iconPositions);

      if (hasChanges || this.asset.newlyAdded) {
        this.assetEditorService.setUnsavedAsset(this.asset);
      } else {
        this.assetEditorService.removeUnsavedAsset(this.asset);
      }
    }

    this.hasChanges = hasChanges;
  }

  private checkPath(pathString: string): boolean {
    if (!this.asset) {
      return true;
    }
    if (!pathString && this.asset.category === SisMapAssetCategory.CustomPath) {
      return false;
    }
    if (!pathString && this.asset.category !== SisMapAssetCategory.CustomPath) {
      return this.asset.iconPositions?.length > 0;
    }

    const reEverythingAllowed = /[MZLHVCSQTA0-9-,.\s]/g;

    const bContainsIllegalCharacter = !!pathString.replace(reEverythingAllowed, '').length;
    const bContainsAdjacentLetters = /[a-zA-Z][a-zA-Z]/.test(pathString);
    const bInvalidStart = /^[0-9-,.]/.test(pathString);
    const bInvalidEnd = /.*[-,.]$/.test(pathString.trim());

    const path = parseCurveCoords(pathString);

    return !(bContainsIllegalCharacter || bContainsAdjacentLetters || bInvalidStart || bInvalidEnd) && path != null;
  }

  private validatePath(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const pathControl = control.get(AssetEditorService.path);

      const valid = this.checkPath(pathControl?.value);

      if (!valid) {
        pathControl?.setErrors({ path: true });
        return { path: true };
      }

      pathControl?.setErrors(null);
      return null;
    };
  }

  private detectChanges(): void {
    // Need to detach and reattach in order to prevent race conditions
    // with the default Angular change-detector
    this.changeDetector.detach();
    this.changeDetector.detectChanges();
    this.changeDetector.reattach();
  }

  private getOriginalAsset(element: MapElement): MapElement {
    this.setOriginalAsset(element);

    const originalAsset = JSON.parse(JSON.stringify(this.assetEditorService.editedAssetOriginals.get(element.guid)));
    return originalAsset;
  }

  private setOriginalAsset(element: MapElement): void {
    if (!this.assetEditorService.editedAssetOriginals.has(element.guid)) {
      const originalAsset = JSON.parse(JSON.stringify(element));

      this.assetEditorService.editedAssetOriginals.set(element.guid, originalAsset);
    }
  }

  private setupArrowkeyListeners(): void {
    const keyDown$: Observable<KeyboardEvent> = fromEvent<KeyboardEvent>(window, 'keydown');
    const keyUp$: Observable<KeyboardEvent> = fromEvent<KeyboardEvent>(window, 'keyup');

    const moveVector$ = merge(
      keyDown$.pipe(
        filter((event) => event.key === 'ArrowUp'),
        map(() => ({ x: 1, y: null }))
      ),
      keyUp$.pipe(
        filter((event) => event.key === 'ArrowUp'),
        map(() => ({ x: 0, y: null }))
      ),
      keyDown$.pipe(
        filter((event) => event.key === 'ArrowRight'),
        map(() => ({ x: null, y: 1 }))
      ),
      keyUp$.pipe(
        filter((event) => event.key === 'ArrowRight'),
        map(() => ({ x: null, y: 0 }))
      ),
      keyDown$.pipe(
        filter((event) => event.key === 'ArrowDown'),
        map(() => ({ x: -1, y: null }))
      ),
      keyUp$.pipe(
        filter((event) => event.key === 'ArrowDown'),
        map(() => ({ x: 0, y: null }))
      ),
      keyDown$.pipe(
        filter((event) => event.key === 'ArrowLeft'),
        map(() => ({ x: null, y: -1 }))
      ),
      keyUp$.pipe(
        filter((event) => event.key === 'ArrowLeft'),
        map(() => ({ x: null, y: 0 }))
      )
    ).pipe(
      scan(
        (acc, c) => {
          if (c.x != null) {
            acc.x = c.x;
          }
          if (c.y != null) {
            acc.y = c.y;
          }
          return acc;
        },
        { x: 0, y: 0 }
      )
    );

    moveVector$.pipe(takeUntil(this.onDestroy$)).subscribe(({ x, y }) => {
      if (this.asset && this.selectedIconIndex != null) {
        this.asset.iconPositions[this.selectedIconIndex].x += x;
        this.asset.iconPositions[this.selectedIconIndex].y += y;

        this.iconPositionInputs.at(this.selectedIconIndex).patchValue(this.asset.iconPositions[this.selectedIconIndex]);
      }
    });
  }

  private validateProperty(control: AbstractControl): ValidationErrors | null {
    const { value, root } = control;
    if (root == null || root === control) {
      return null;
    }
    const controlName = this.getControlName(control);
    let valid = true;

    if (controlName === AssetEditorService.path) {
      valid = this.checkPath(control.value);
    } else {
      const key = controlName;
      const property = this.config.editableProperties.find((p) => p.key === key);
      const nullCheckFailed = !property.isNullable && (value == null || value === '');
      const numberCheckFailed =
        property.isNumber && isNaN(Number(value)) && !(property.isNullable && (value == null || value === ''));
      valid = !nullCheckFailed && !numberCheckFailed;
    }

    let error = null;

    if (!valid) {
      this.errors.add(controlName);
      error = { [controlName]: true };
    } else {
      this.errors.delete(controlName);
    }

    if (this.errors.size === 0) {
      this.assetEditorService.removeAssetWithError(this.asset);
    } else {
      this.assetEditorService.setAssetWithError(this.asset);
    }

    return error;
  }

  private getControlName(c: AbstractControl): string | null {
    const formGroup = c.parent.controls;
    return Object.keys(formGroup).find((name) => c === formGroup[name]) || null;
  }
}
